<template>
  <div class="vehicle-management" ref="userManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddVehicle"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="importExcel"
        v-if="$store.state.menu.nowMenuList.indexOf('导入') >= 0"
        >导入</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="96px"
      >
        <el-form-item label="车牌号：">
          <el-input
            v-model.trim="form.cph"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="车架号：">
          <el-input
            v-model.trim="form.vehicleNo"
            placeholder="请输入车架号"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属企业：">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="车辆类型：">
          <el-select v-model="form.type" clearable placeholder="请选择车辆类型">
            <el-option
              v-for="item in typeList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆状态：">
          <el-select
            v-model="form.vehicleStatus"
            clearable
            placeholder="请选择车辆状态"
          >
            <el-option
              v-for="item in vehicleStatusList"
              :label="item.dictValue"
              :value="item.dictCode"
              :key="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="终端编号：">
          <el-input
            v-model.trim="form.deviceNo"
            placeholder="请输入终端编号"
          ></el-input>
        </el-form-item>
        <el-form-item label="终端自编号：">
          <el-input
            v-model.trim="form.serialNo"
            placeholder="请输入自编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="120"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="车辆类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          typeObj[Number(scope.row.type)]
        }}</template>
      </el-table-column>
      <el-table-column prop="powerType" label="动力类型">
        <template slot-scope="scope">{{
          powerTypeObj[Number(scope.row.powerType)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="deviceNo"
        label="设备号"
        :show-overflow-tooltip="true"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="vehicleNo"
        label="车架号"
        :show-overflow-tooltip="true"
        width="190"
      ></el-table-column>
      <el-table-column
        prop="sim"
        label="SIM卡号"
        :show-overflow-tooltip="true"
        width="140"
      ></el-table-column>
      <el-table-column prop="model" label="车型" width="80">
        <template slot-scope="scope">{{
          modelObj[Number(scope.row.model)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="deviceType"
        label="设备类型"
        :show-overflow-tooltip="true"
        width="80"
      ></el-table-column>
      <el-table-column prop="vehicleStatus" label="车辆状态" width="80">
        <template slot-scope="scope">{{
          vehicleStatusObj[Number(scope.row.vehicleStatus)]
        }}</template>
      </el-table-column>
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateVehicle(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="70%"
      custom-class="add-vehicle-dialog"
    >
      <addVehicle
        ref="addVehicle"
        :vehicleNo="vehicleNo"
        :rowData="rowData"
        @saveEnd="saveEnd"
        @onLoading="onLoading"
      />

      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入"
      v-if="downVisible"
      :visible.sync="downVisible"
      width="30%"
    >
      <upload-file
        @uploadSuccess="uploadSuccess"
        url="base/vehicle/importDriver"
        class="import"
        :type="0"
      ></upload-file>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVehicleList,
  queryDictsByCodes,
  getBusinessCompanyTree
} from '@/api/lib/api.js'
import { formatDict, getCurent, formatDate } from '@/common/utils/index'
import uploadFile from '@/components/uploadFile/uploadFile.vue'
import companySelect from '@/components/treeSelect/companySelect.vue'
import addVehicle from './components/addVehicle.vue'

import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'vehicleManagement',
  components: {
    uploadFile,
    companySelect,
    addVehicle
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      form: {
        cph: '',
        vehicleNo: '',
        companyName: [],
        type: '',
        vehicleStatus: null,
        deviceNo: '',
        currentPage: 1,
        pageSize: 10
      },
      typeList: [],
      vehicleStatusList: [],
      typeObj: {},
      vehicleStatusObj: {},
      powerTypeObj: {},
      modelObj: {},
      deviceTypeObj: {},
      total: 0,
      dialogVisible: false,
      title: '',
      companyList: [],
      vehicleNo: '',
      rowData: null,
      editLoading: false,
      exportForm: {},
      downLoadStatus: false,
      downVisible: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.userManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'base',
        userId: sessionStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `车辆信息-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/vehicle/vehicleExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    onSearch () {
      this.form.currentPage = 1
      this.getTableData()
    },
    // 获取表格数据
    getTableData () {
      getVehicleList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
        this.exportForm = { ...this.form }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getTableData()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getTableData()
    },
    // 下载模板
    onExport () {
      this.downLoadStatus = true
      let data = {
        baseUrl: 'base',
        fileName: `车辆导入模板-${getCurent()}`,
        queryParam: '',
        queryPath: '/vehicle/importVehicleTemplateExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    // 点击表格查看按钮
    onUpdateVehicle (row) {
      this.title = '修改车辆信息'
      this.rowData = { ...row }
      this.vehicleNo = row.vehicleNo

      this.dialogVisible = true
    },
    // 新增车辆
    onAddVehicle () {
      this.title = '新增车辆'
      this.rowData = null
      this.vehicleNo = ''
      this.dialogVisible = true
    },

    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    getGroupId (v) {
      this.form.companyId = v
    },
    onSave () {
      this.$refs.addVehicle.onSave()
    },
    saveEnd () {
      this.dialogVisible = false
      this.getTableData()
    },
    onLoading (flag) {
      this.editLoading = flag
    },
    //导入
    importExcel () {
      this.downVisible = true
    },
    //导入成功
    uploadSuccess () {
      this.downVisible = false
      this.onSearch()
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'VETY,CLZT,DLLX,ZDLX,CX' }).then(
        (res) => {
          if (res.code === 1000) {
            this.typeList = res.data.VETY
            this.vehicleStatusList = res.data.CLZT
            this.powerTypeObj = formatDict(res.data.DLLX)
            this.modelObj = formatDict(res.data.CX)
            this.deviceTypeObj = formatDict(res.data.ZDLX)
            this.typeObj = formatDict(this.typeList)
            this.vehicleStatusObj = formatDict(this.vehicleStatusList)
          }
        }
      )
    }
  },
  created () {
    this.getCompanyList()
    this.getDicts()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.vehicle-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-resourse {
  .vehicle-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
